<template>
    <div class="course-basic-information">
        <el-form-item label="巡课年级" prop="gradeId">
            <ElSelectTree
                style="width: 70%;"
                v-model="ruleForm.gradeId"
                :data="gradeList"
                :props="{
                    value: 'id',
                    label: (data) => data.name,
                    children: 'child'
                }"
                filterable
                :disabled="tourDetailsClone.type == 'CourseSchedule' || tourDetailsClone.type == 'edit' || tourDetailsClone.type == 'detail' || tourDetailsClone.type == 'continueTour'"
                @change="handlerTourGrade"
            />
        </el-form-item>
        <el-form-item label="巡课班级" prop="classId">
            <ElSelectTree
                style="width: 70%;"
                v-model="ruleForm.classId"
                :data="classList"
                :props="{
                    value: 'id',
                    label: (data) => data.name,
                    children: 'child'
                }"
                check-strictly
                filterable
                :disabled="tourDetailsClone.type == 'CourseSchedule' || tourDetailsClone.type == 'edit' || tourDetailsClone.type == 'detail' || tourDetailsClone.type == 'continueTour'"
                @change="handlerClassIdChange"
            />
        </el-form-item>
        <el-form-item label="上课日期" prop="classTime">
            <el-date-picker 
                style="width: 70%;"
                type="date" 
                placeholder="选择日期" 
                v-model="ruleForm.classTime"
                value-format="yyyy-MM-dd"
                :disabled="tourDetailsClone.type == 'CourseSchedule' || tourDetailsClone.type == 'edit' || tourDetailsClone.type == 'detail' || tourDetailsClone.type == 'continueTour'"
                @change="handlerClassTimeChange"
            >
            </el-date-picker>
        </el-form-item>
        <el-form-item label="课程" prop="courseId">
            <el-select 
                style="width: 70%;" 
                v-model="ruleForm.courseId" 
                placeholder="请选择课程"
                :disabled="tourDetailsClone.type == 'CourseSchedule' || tourDetailsClone.type == 'edit' || tourDetailsClone.type == 'detail' || tourDetailsClone.type == 'continueTour'"
                @change="handlerCourseIdChange"
            >
                <el-option
                    v-for="item in courseList"
                    :key="item.id"
                    :label="item.subjectName"
                    :value="item.id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="上课时间" prop="restTimeId">
            <el-select 
                style="width: 70%;" 
                v-model="ruleForm.restTimeId" 
                placeholder="请选择上课时间段"
                :disabled="true"
                @change="handlerRestTimeIdChange"
            >
                <el-option
                    v-for="item in classPeriodList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="督导教师" prop="supervisingTeacherId">
            <!-- <ElSelectTree
                
                v-model="ruleForm.supervisingTeacherId"  
                :disabled="tourDetailsClone.type == 'CourseSchedule' || tourDetailsClone.type == 'edit' || tourDetailsClone.type == 'detail'" 
                :data="supervisingTeachersList"
                :props="{
                    value: 'id',
                    label: (data) => data.name,
                    children: 'children'
                }"
                filterable
                style="width: 70%;"
                @change="handlerSupervisingTeacherChange"
            /> -->
            <select-tree
                v-if="tourDetailsClone.type != 'continueTour' || tourDetailsClone.type != 'add'"
                v-model="ruleForm.supervisingTeacherId"
                :disabled="tourDetailsClone.type == 'CourseSchedule' || tourDetailsClone.type == 'edit' || tourDetailsClone.type == 'detail' || tourDetailsClone.type == 'continueTour'" 
                style="width: 70%;"
                :data="supervisingTeachersList"
                :props="{
                    value: 'id',
                    label: (data) => data.name,
                    children: 'children'
                }"
                @handlerSupervisingTeacherChange="handlerSupervisingTeacherChange"
            >
            </select-tree>
            <el-select 
                v-else
                v-model="ruleForm.supervisingTeacherId" 
                filterable 
                style="width: 70%;"
                @change="handlerSupervisingTeacherChange"
                >
                <virtual-list style="max-height: 200px; overflow-y: auto;"
                    :data-key="'id'"
                    :data-sources="supervisingTeachersList1"
                    :data-component="itemComponent"
                    :keeps="20"
                    :extra-props="{
                        label: 'name',
                        value: 'id'
                        }" /> 
            </el-select>

            <!-- continueTour 继续巡课/  add 添加-->
        </el-form-item>
    </div>
</template>
<script>
    import Vue from 'vue';
    import ElSelectTree from 'el-select-tree';
    Vue.use(ElSelectTree);

    import virtualList from 'vue-virtual-scroll-list'
    import ElOptionNode from '../CourseScheduleView/VirtulaList/ElOptionNode.vue';
    import SelectTree from '../../TeachingResearchCourseScheduleTemp/Sub/SelectTree.vue'
    export default {
        name: "CourseBasicInformation",
        components: {
            ElSelectTree,
            'virtual-list': virtualList,
            SelectTree
        },
        props: {
            ruleForm: Object,
            gradeList: Array,
            classList: Array,
            courseList: Array,
            classPeriodList: Array,
            supervisingTeachersList: Array,
            supervisingTeachersList1: Array,
            tourDetailsClone: Object
        },
        data () {
            return {
                itemComponent: ElOptionNode,
            }
        },
        methods: {
            handlerTourGrade () {
                this.$eventDispatch('handlerTourGrade')
            },
            handlerSupervisingTeacherChange () {
                this.$eventDispatch('handlerSupervisingTeacherChange')
            },
            handlerCourseIdChange () {
                this.$eventDispatch('handlerCourseIdChange')
            },
            handlerRestTimeIdChange () {
                this.$eventDispatch('handlerRestTimeIdChange')
            },
            handlerClassIdChange () {
                this.$eventDispatch('handlerClassIdChange')
            },
            handlerClassTimeChange () {
                this.$eventDispatch('handlerClassTimeChange')
            },
        },
        watch: {
            'supervisingTeachersList' : {
                handler () {
                    console.log(this.supervisingTeachersList,'supervisingTeachersList 数据')
                },
                deep: true
            }
        }
    }
</script>
<style lang="scss" scoped>
    .course-basic-information {
        box-sizing: border-box;
        padding: 24px 0;
    }
</style>