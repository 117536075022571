<template>
    <div class="class-patrol-form-wrapper">
        <el-form 
            :model="ruleForm" 
            :rules="rules" 
            ref="ruleForm" 
            label-width="100px" 
            class="ruleForm-layout">
            <div class="form-left">
                <FormTitle title="课程基本信息"/>
                <course-basic-information 
                    :ruleForm="ruleForm"
                    :gradeList="gradeList"
                    :classList="classList"
                    :courseList="courseList"
                    :classPeriodList="classPeriodList"
                    :supervisingTeachersList="supervisingTeachersList"
                    :supervisingTeachersList1="supervisingTeachersList1"
                    :tourDetailsClone="tourDetailsClone"
                >
                </course-basic-information>
                <FormTitle title="设置项"/>
                <setting-items 
                    :ruleForm="ruleForm"
                    :tourDetailsClone="tourDetailsClone"
                ></setting-items>
            </div>
            <div class="form-right">
                <Evaluation 
                    :formTabsList="formTabsList"
                    :formTabsPointer="formTabsPointer"
                    :evaluationForm="evaluationForm"
                    :tourDetailsClone="tourDetailsClone"
                />
            </div>
        </el-form>
    </div>
</template>
<script>
import FormTitle from './Sub/FormTitle.vue';
import CourseBasicInformation from './CourseBasicInformation.vue';
import SettingItems from './SettingItems.vue';
import Evaluation from './Evaluation.vue';

export default {
    name: "ClassPatrolForm",
    components: {
        FormTitle,
        CourseBasicInformation,
        SettingItems,
        Evaluation
    },
    props: {
        ruleForm: Object,
        rules: Object,
        gradeList: Array,
        classList: Array,
        courseList: Array,
        classPeriodList: Array,
        supervisingTeachersList: Array,
        supervisingTeachersList1: Array,
        formTabsList: Array,
        formTabsPointer: String,
        evaluationForm: Array,
        tourDetailsClone: Object
    },
    methods: {
        resetFields () {
            this.$nextTick(() => {
                this.$refs.ruleForm.resetFields()
            })
        },
    }

}
</script>
<style lang="scss" scoped>
    .class-patrol-form-wrapper {
        height: calc(100vh - 240px);
        overflow: auto;
        box-sizing: border-box;
        padding: 40px 14px 16px;
        .ruleForm-layout {
            display: flex;
            .form-left {
                width:40%;
                // flex: 1;
            }
            .form-right {
                // margin-left: 20px;
                // flex: 1;
                width: 60%;
            }

        }
    }
</style>