<template>
    <div class="evaluation-wrapper">
        <div class="evaluation-title">
            <FormTitle title="评价"/>
            <form-tab 
                :formTabsList="formTabsList"
                :formTabsPointer="formTabsPointer"
                :tourDetailsClone="tourDetailsClone"
            ></form-tab>
        </div>
        <div class="clear-fixed">
            <teacher-evaluation 
                v-if="formTabsPointer === '0'"
                :data="selData"
                :num="0"
                :tourDetailsClone="tourDetailsClone"
            >
            </teacher-evaluation>
            <teacher-evaluation 
                v-if="formTabsPointer === '1'"
                :data="selData"
                :num="1"
                :tourDetailsClone="tourDetailsClone"
            >
            </teacher-evaluation>
            <Highlights 
                v-if="formTabsPointer === '2'"
                :data="selData"
                :tourDetailsClone="tourDetailsClone"
            />
            <Problem 
                v-if="formTabsPointer === '3'"
                :data="selData"   
                :tourDetailsClone="tourDetailsClone" 
            />
            <Remarks 
                v-if="formTabsPointer === '4'"
                :data="selData"
                :tourDetailsClone="tourDetailsClone"
            />
        </div>
    </div>
</template>
<script>
    import FormTitle from './Sub/FormTitle.vue'
    import FormTab from './Sub/FormTab.vue';
    import TeacherEvaluation from './Sub/InfoTeacherEvaluation.vue';
    import Highlights from './Sub/Highlights.vue';
    import Problem from './Sub/Problem.vue';
    import Remarks from './Sub/Problem.vue';

    export default {
        name: "EvaluationWrapper",
        components: {
            FormTitle,
            FormTab,
            TeacherEvaluation,
            Highlights,
            Problem,
            Remarks
        },
        props: {
            formTabsList: Array,
            formTabsPointer: String,
            evaluationForm: Array,
            tourDetailsClone: Object
        },
        computed: {
            selData () {
                let newFormTabsPointer = Number(this.formTabsPointer);
                return this.evaluationForm[newFormTabsPointer]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .evaluation-wrapper {
        
        .evaluation-title {
            display: flex;
            align-items: flex-start;
        }
        .clear-fixed {
            margin-top: 10px;
            border-radius: 4px;
            overflow: hidden;
        }
    }
</style>